import React, { useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import SVG from 'react-inlinesvg';
import { FixedSizeList as List } from 'react-window';

function DietTag({tag}) {
  const tags = {lactose_free: I18n.t("application_helper.lactose_free_tag"), vegetarian_tag: I18n.t("application_helper.vegetarian_tag"), vegan_tag: I18n.t("application_helper.vegan_tag"), milkless: I18n.t("application_helper.dairy_free_tag"), gluten_free: I18n.t("application_helper.gluten_free_tag"), fodmap: I18n.t("application_helper.fodmap_tag")}
  return (
    <span className="diet_icon diet_icon--small size-s">{tags[tag]}</span>
  )
}

function Recommendation({recommendation, oldRecipeEl, mealTime, callback}) {
  return (
    <div className="carousel-card padding-0-5 margin-0-25 bg-cover flex align-center">
      <div className="flex align-center" onClick={onRecommendationNavigate.bind(this, recommendation.show_url)}>
        <div className="lazy margin-0-5 margin-right" data-src={recommendation.x200_image_url} style={{backgroundColor: "#eee", backgroundImage: 'url(' + recommendation.x200_image_url + ')', width: "50px", height: "50px", backgroundSize: "cover", backgroundPosition: "center", borderRadius: "100px"}}>
        </div>
        <div>
          <div className="size-m crop-title">
            {recommendation.name}
              <div>
              {recommendation.diet_tags.map(tag => (
                <DietTag key={tag} tag={tag} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{marginLeft: "auto"}} onClick={onRecommendationPick.bind(this, oldRecipeEl, recommendation, mealTime, callback)}>
        <img src="/check.svg" />
      </div>
    </div>
  );
}

function close() {
  $("#react-recommendations").hide();
  ReactDOM.unmountComponentAtNode(document.getElementById('react-recommendations'))
}

function onRecommendationPick(oldRecipeEl, newRecipe, mealTime, callback) {
  window[callback] && window[callback](oldRecipeEl, newRecipe, mealTime);
  ReactDOM.unmountComponentAtNode(document.getElementById('react-recommendations'))
}

function onRecommendationNavigate(url) {
  if (url) {
    window.location = "viaesca://close" + url;
  }
}

export default function Recommendations({ mealTime, oldRecipeEl, callback, showFineliMeals }) {
  const [selectedOption, setSelectedOption] = useState(mealTime || null)
  const [items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [filterText, setFilterText] = useState("")
  const [loading, setLoading] = useState(true)
  const [favoriteToggle, setFavoriteToggle] = useState(false)
  const container = useRef(null);

  useEffect(() => {
    queryRecommendations()
  }, []);

  useEffect(() => {
    queryRecommendations()
  }, [favoriteToggle, selectedOption])

  useEffect(() => {
    var newFilteredItems = []
    if (filterText.length == 0) {
      newFilteredItems = items
    } else {
      newFilteredItems = items.filter(function(item){
        return item.name.toLowerCase().search(filterText.toLowerCase()) !== -1;
      });
    }
    setFilteredItems(newFilteredItems)
  }, [filterText])

  const handleFilterChange = (value) => {
    setFilterText(value)
  }

  const encodeQueryData = (data) => {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
  }

  const queryRecommendations = () => {
    setLoading(true)
    let data = {};
    if (selectedOption && selectedOption.length > 0) {
      data['meal_time'] = selectedOption
    }
    if (favoriteToggle == true) {
      data['only_favorites'] = true
    }
    if (showFineliMeals == true) {
      data['include_fineli_meals'] = true
    }
    const url = "/recipes_recommendations.json?" + encodeQueryData(data)

    fetch(url, { method: "get" })
    .then(function(response) {
      return response.json();
    })
    .then(function(recommendations) {
      let combinedItems = recommendations.recommendations.concat(recommendations.fineli_meals)
      setItems(combinedItems)
      setFilteredItems(combinedItems)
      let oldFilterText = filterText
      setFilterText("")
      setFilterText(oldFilterText)
      setLoading(false)
    })
  }

  const handleChange = (value) => {
    setFilteredItems([])
    setSelectedOption(value)
  }

  const handleFavoriteToggle = (value) => {
    setFilteredItems([])
    setFavoriteToggle(value)
  }

  const ListItem = ({data, index, style}) => {
    const recommendation = data.filteredItems[index];
    return <div style={style}><Recommendation key={index} recommendation={recommendation} oldRecipeEl={data.oldRecipeEl} mealTime={mealTime} callback={data.callback}/></div>;
  }

  return (
    <div className="height-100">
      <div className="closeRecommendations" onClick={close.bind(this)}>×</div>
      <div className="flex vertical height-100">
        <div className="recommendationSearchWrapper bg-8">
          <div className="size-s all-caps color-9">
            {I18n.t("react-recommendations.recipes_collection")}
          </div>
          <div className="custom-select margin-0-5 margin-bottom">
            <select name="mealTime" value={selectedOption} onChange={(event) => { handleChange(event.target.value) }}>
              <option value="">{ I18n.t("react-recommendations.all_recipes") }</option>
              <option value="breakfast">{ I18n.t("react-recommendations.breakfasts") }</option>
              <option value="lunch">{ I18n.t("react-recommendations.lunches") }</option>
              <option value="snack">{ I18n.t("react-recommendations.snacks") }</option>
              <option value="dinner">{ I18n.t("react-recommendations.dinners") }</option>
              <option value="supper">{ I18n.t("react-recommendations.suppers") }</option>
              {showFineliMeals &&
                <option value="recent">{ I18n.t("react-recommendations.last_added") }</option>
              }
            </select>
          </div>
          <div className="custom-checkbox margin-0-5 margin-bottom boolean optional">
            <input className="boolean optional" type="checkbox" checked={favoriteToggle} onChange={(event) => { handleFavoriteToggle(event.target.checked) }} id="favoriteToggle"/>
            <label className="boolean optional" htmlFor="favoriteToggle">{ I18n.t("react-recommendations.show_only_favorites") }</label>
          </div>
          <div className="">
            <input className="width-100 padding-0-5 size-ml light search-field" type="text" value={filterText} onChange={(event) => { handleFilterChange(event.target.value) }} placeholder={I18n.t("react-recommendations.search")}/>
          </div>
        </div>
        <div ref={container} style={{flex: "auto"}}>
          <List
            height={container.current ? container.current.offsetHeight : 0}
            itemCount={filteredItems.length}
            itemSize={66}
            width={"100%"}
            itemData={{filteredItems, callback, oldRecipeEl}}
          >
            {ListItem}
          </List>
        </div>
        {loading &&
          <div className="loading">
            <img src="/big-spinner.gif" />
            <br/>
            {I18n.t("scheduled_day_plannings.new.please_wait")}
          </div>
        }
        {!loading && filteredItems == 0 &&
          <div className="loading">
            <img src="/close.svg" />
            <br/>
            { I18n.t("react-recommendations.no_seach_found") }
          </div>
        }
      </div>
    </div>
  );
}
