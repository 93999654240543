import React from 'react';
import ReactDOM from 'react-dom';
import Recommendations from './recommendations';

function renderRecommendations(options) {
  if (document.getElementById('react-recommendations')) {
    ReactDOM.render(
      <Recommendations {...options} />,
      document.getElementById('react-recommendations')
    );
  }
}

window.showRecommendations = (oldRecipeEl, mealTime, callback, showFineliMeals) => {
  renderRecommendations({oldRecipeEl, mealTime, callback, showFineliMeals});
}
